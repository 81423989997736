import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLinkedinIn, faSafari } from "@fortawesome/free-brands-svg-icons";
import {
  faCog,
  faEnvelope,
  faHome,
  faKey,
  faQuestionCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {
  CSSProperties,
  ReactFragment,
  useEffect,
  useState,
} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  digitalizationImage,
  hamburgerMenuImage,
  twinOakCombinedImage,
} from "../images";
import { ExpandableImageProvider } from "./ExpandableImage";
import "./TwinOak.scss";

interface PageLayoutProps {
  baseContainerClassname: string;
  heroBackgroundImage: string;
  heroOverlayLeadElement: ReactFragment;
  heroOverlaySecondaryElement: ReactFragment;
}

const defaultProps: PageLayoutProps = {
  baseContainerClassname: "",
  heroBackgroundImage: `url(${digitalizationImage})`,
  heroOverlayLeadElement: (
    <>
      Time to modernize&nbsp;<strong>your digital&nbsp;</strong>
      products?
    </>
  ),
  heroOverlaySecondaryElement: <>We can help.</>,
};

interface NavLinkProps {
  icon: IconProp;
  text: string;
  to: string;
  /** True if rendering at mobile resolution */
  mobile?: boolean;
  /**
   * A silly prop used to add extra behavior to the click event.  Currently used to collapse the mobile menu when the
   * user clicks the link for the current page.  Otherwise, the menu would stay open.
   */
  onClick?(): void;
}

const NavLink: React.VFC<NavLinkProps> = ({
  icon,
  text,
  to,
  mobile,
  onClick,
}) => {
  return (
    <Link
      to={to}
      className={mobile ? "tos_mobile_nav_element" : "tos_nav_element"}
      onClick={onClick}
    >
      <div className="tos_nav_ico_block">
        <FontAwesomeIcon icon={icon} />
      </div>
      <p className="tos_nav_txt">{text}</p>
    </Link>
  );
};

/**
 * This component provides the overall structure used by all the pages.  It includes the navigation, header, hero
 * image, and footer.
 */
export const PageLayout: React.FC<Partial<PageLayoutProps>> = (props) => {
  const {
    baseContainerClassname,
    heroBackgroundImage,
    heroOverlayLeadElement,
    heroOverlaySecondaryElement,
    children,
  } = { ...defaultProps, ...props };
  const heroStyle: CSSProperties = {
    backgroundImage: heroBackgroundImage || `url(${digitalizationImage})`,
    backgroundPositionY: "top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  function handleHamburgerClick() {
    setMobileMenuVisible((prevState) => !prevState);
  }
  function handleOutsideMenuClick() {
    setMobileMenuVisible(false);
  }
  function handleMobileNavMenuItemClick() {
    setMobileMenuVisible(false);
  }

  function MobileNavLink({ icon, text, to }: Omit<NavLinkProps, "onClick">) {
    return (
      <NavLink
        mobile
        icon={icon}
        text={text}
        to={to}
        onClick={handleMobileNavMenuItemClick}
      />
    );
  }

  return (
    <ExpandableImageProvider>
      <div className="tos_site">
        <OutsideClickHandler onOutsideClick={handleOutsideMenuClick}>
          {/* <!-- Header Component --> */}
          <div className="tos_header_outer">
            <div className="tos_header_inner">
              <img className="tos_brand_logo" src={twinOakCombinedImage} />
              <div className="tos_nav_container">
                <NavLink icon={faHome} text="Home" to="/" />
                <NavLink icon={faKey} text="Features" to="/features" />
                <NavLink icon={faCog} text="Our Approach" to="/our-approach" />
                <NavLink icon={faSafari} text="Tour" to="/tour" />
                <NavLink icon={faUsers} text="Team" to="/team" />
                <NavLink icon={faQuestionCircle} text="Q&amp;A" to="/faq" />
                <NavLink icon={faEnvelope} text="Contact" to="/contact-us" />
              </div>
              <div className="tos_mobile_hmenu_btn">
                <img src={hamburgerMenuImage} onClick={handleHamburgerClick} />
              </div>
            </div>
          </div>

          <div
            id="tos_mobile_nav"
            className={`tos_mobile_nav_block ${
              mobileMenuVisible ? "" : "tos_hide"
            }`}
          >
            <div className="tos_mobile_nav">
              <MobileNavLink icon={faHome} text="Home" to="/" />
              <MobileNavLink icon={faKey} text="Features" to="/features" />
              <MobileNavLink
                icon={faCog}
                text="Our Approach"
                to="/our-approach"
              />
              <MobileNavLink icon={faSafari} text="Tour" to="/tour" />
              <MobileNavLink icon={faUsers} text="Team" to="/team" />
              <MobileNavLink icon={faQuestionCircle} text="Q&amp;A" to="/faq" />
              <MobileNavLink
                icon={faEnvelope}
                text="Contact"
                to="/contact-us"
              />
            </div>
          </div>
        </OutsideClickHandler>
        {/* <!-- Header Component End--> */}

        {/* <!-- Page Body Component --> */}
        <div className="tos_site_content">
          <div className={`base_container ${baseContainerClassname}`}>
            {/* <!-- Hero Image Component --> */}
            <div className="tos_comp_hero_image" style={heroStyle}>
              <div className="tos_hero_max">
                <div className="tos_comp_hero_image_text">
                  <div className="tos_comp_hero_text_block">
                    <p className="tos_comp_hero_text_lead">
                      {heroOverlayLeadElement}
                    </p>
                    <p className="tos_comp_hero_text_secondary">
                      {heroOverlaySecondaryElement}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
        {/* <!-- Page Body Component End --> */}

        {/* <!-- Footer Component --> */}
        <div className="tos_footer">
          <div className="tos_footer_inner">
            <div className="tos_footer_element">
              <p className="tos_footer_text">
                Copyright&nbsp;© Twin Oak Solutions 2021
              </p>
            </div>
            <div className="tos_footer_element">
              <a href="https://www.linkedin.com/company/71534153/">
                <div className="tos_footer_sm_icon">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </div>
              </a>
            </div>
            <div className="tos_footer_element">
              {/* <p className="tos_footer_text">
                <Link to="/privacy">Privacy</Link>
              </p>
              <p className="tos_footer_text">
                <Link to="/policy">Policy</Link>
              </p>
              <p className="tos_footer_text">
                <Link to="/terms-of-use">Terms of Use</Link>
              </p> */}
              <p className="tos_footer_text">
                <Link to="/faq">FAQs</Link>
              </p>
            </div>
          </div>
        </div>
        {/* <!-- Footer Component End --> */}
      </div>
    </ExpandableImageProvider>
  );
};
