import React, { useCallback } from "react";
import Lightbox from "react-image-lightbox";
import { ExpandableImageProps } from "./ExpandableImageProps";
import "../LightBox.scss";

interface State {
  expandedImageIndex?: number;
  images: ExpandableImageProps[];

  setExpandedImageSrc(src: string): void;
  addImage(image: ExpandableImageProps): void;
  removeImage(image: ExpandableImageProps): void;
}

const ExpandableImageContext = React.createContext<State | null>(null);
const { Provider } = ExpandableImageContext;

export const ExpandableImageProvider: React.FC = ({ children }) => {
  // A collection of all the rendered expandable images.  We need the whole list so we can support the gallery view
  // used by the user to scroll through all the images.
  const [images, setImages] = React.useState([] as ExpandableImageProps[]);

  // Index of the image currently expanded or -1 if no image is expanded.
  const [expandedImageIndex, setExpandedImageIndex] = React.useState(-1);

  function addImage(image: ExpandableImageProps) {
    setImages((prevState) => {
      const expandedImageIndex = prevState.findIndex(
        (i) => i.src === image.src
      );
      return expandedImageIndex !== -1 ? prevState : [...prevState, image];
    });
  }

  function removeImage(image: ExpandableImageProps) {
    setImages((prevState) => prevState.filter((i) => i.src !== image.src));
  }

  const setExpandedImageSrc = useCallback(
    function (src: string) {
      const expandedImageIndex = images.findIndex((i) => i.src === src);
      if (expandedImageIndex === -1) {
        return;
      }
      setExpandedImageIndex(expandedImageIndex);
    },
    [images, expandedImageIndex]
  );

  return (
    <Provider
      value={{
        images,
        expandedImageIndex,
        setExpandedImageSrc,
        addImage,
        removeImage,
      }}
    >
      {expandedImageIndex !== -1 && (
        <Lightbox
          mainSrc={images[expandedImageIndex].src}
          imageTitle={images[expandedImageIndex].expandedTitle}
          imageCaption={images[expandedImageIndex].expandedCaption}
          nextSrc={images[(expandedImageIndex + 1) % images.length].src}
          prevSrc={
            images[(expandedImageIndex + images.length - 1) % images.length].src
          }
          onCloseRequest={() => setExpandedImageIndex(-1)}
          onMovePrevRequest={() => {
            setExpandedImageIndex(
              (expandedImageIndex + images.length - 1) % images.length
            );
          }}
          onMoveNextRequest={() => {
            setExpandedImageIndex((expandedImageIndex + 1) % images.length);
          }}
        />
      )}
      {children}
    </Provider>
  );
};

export function useExpandableImageState() {
  const context = React.useContext(ExpandableImageContext);
  if (!context) {
    throw new Error(
      "useExpandableImageState must be used within an ExpandableImageProvider"
    );
  }
  return context;
}
