import acornBlackImage from "./acorn_black.svg";
import architecturalOverviewImage from "./ArchitecturalOverview.svg";
import bwbHomepageImage from "./bwb_homepage.png";
import cartoon_guy_handshake_png from "./cartoon_guy_handshake.png";
import cartoon_guy_handshake from "./cartoon_guy_handshake.svg";
import cartoon_guy_road_thumbs_up_png from "./cartoon_guy_road_thumbs_up.png";
import cartoon_guy_road_thumbs_up from "./cartoon_guy_road_thumbs_up.svg";
import cartoon_guy_with_question_marks_png from "./cartoon_guy_with_question_marks.svg";
import compass1Image from "./Compass1.png";
import compass2Image from "./Compass2.png";
import compass3Image from "./Compass3.png";
import compass4Image from "./Compass4.png";
import implementationOverviewImagePng from "./conversion_overview.png";
import implementationOverviewImage from "./conversion_overview.svg";
import teamPageHeroImage from "./hero/appdevsdon.jpg";
import {
  default as cicdTourPageHeroImage,
  default as customCodeTourPageHeroImage,
  default as digitalizationImage,
  default as fourOhFourPageHeroImage,
  default as homePageHeroImage,
  default as kitchenSinkPageHeroImage,
  default as policyPageHeroImage,
  default as privacyPageHeroImage,
  default as termsOfUsePageHeroImage,
} from "./hero/digitalization.jpeg";
import ourApproachPageHeroImage from "./hero/photo-1595896424050-f70867364590.webp";
import tourHomePageHeroImage from "./hero/screen15don.jpg";
import featuresPageHeroImage from "./hero/screen16don.jpg";
import developerTourPageHeroImage from "./hero/screen2don.jpg";
import faqPageHeroImage from "./hero/screen30don.jpg";
import contactUsPageHeroImage from "./hero/screen33don2.jpg";
import userTourPageHeroImage from "./hero/screen4don.jpg";
import hamburgerMenuImage from "./h_menu.svg";
import mapImage from "./map-image.png";
import approachOverviewImage from "./OverallApproach.svg";
import screenshotBwbHomepageImage from "./screenshots/bwb_homepage.png";
import screenshotDataAfterImage from "./screenshots/Data_After.png";
import screenshotDataBeforeImage from "./screenshots/Data_Before.png";
import screenshotDfuAfterImage from "./screenshots/DFU_After.png";
import screenshotDfuBeforeImage from "./screenshots/DFU_Before.png";
import screenshotDfuSourceAfterImage from "./screenshots/DFU_Source_After.png";
import screenshotDfuSourceBeforeImage from "./screenshots/DFU_Source_Before.png";
import screenshotBwbGuestbookImage from "./screenshots/Guestbook.png";
import screenshotMenuAfterImage from "./screenshots/Menu_After.png";
import screenshotMenuBeforeImage from "./screenshots/Menu_Before.png";
import screenshotMenuSourceAfterImage from "./screenshots/Menu_Source_After.png";
import screenshotMenuSourceBeforeImage from "./screenshots/Menu_Source_Before.png";
import screenshotProcedureSourceAfterImage from "./screenshots/Procedure_Source_After.png";
import screenshotProcedureSourceBeforeImage from "./screenshots/Procedure_Source_Before.png";
import screenshotProgramSourceAfterImage from "./screenshots/Program_Source_After.png";
import screenshotProgramSourceBeforeImage from "./screenshots/Program_Source_Before.png";
import screenshotReportListAfterImage from "./screenshots/ReportList_After.png";
import screenshotReportListBeforeImage from "./screenshots/ReportList_Before.png";
import screenshotReportAfterImage from "./screenshots/Report_After.png";
import screenshotReportBeforeImage from "./screenshots/Report_Before.png";
import screenshotScreenAfterImage from "./screenshots/Screen_After.png";
import screenshotScreenBeforeImage from "./screenshots/Screen_Before.png";
import screenshotScreenSourceAfterImage from "./screenshots/Screen_Source_After.png";
import screenshotScreenSourceBeforeImage from "./screenshots/Screen_Source_Before.png";
import spaOverviewImage from "./SPAOverview.svg";
import spaToHostImagePng from "./spa_to_host.png";
import spaToHostImage from "./spa_to_host.svg";
import user1Image from "./team/user1.png";
import user2Image from "./team/user2.png";
import user3Image from "./team/user3.png";
import twinOakSquirrelConversionImagePng from "./tos_logo_conversion.png";
import twinOakSquirrelConversionImage from "./tos_logo_conversion.svg";
import twinOakSquirrelIconImage from "./tos_squirrel_icon.svg";
import tourDeveloperImage from "./tourDeveloper.jpg";
import tourUserImage from "./tourUser.jpg";
import twinOakCombinedImage from "./twin_oak_combined.svg";
import wineImage from "./wine.svg";

export {
  implementationOverviewImage,
  implementationOverviewImagePng,
  spaOverviewImage,
  spaToHostImage,
  spaToHostImagePng,
  twinOakSquirrelConversionImage,
  twinOakSquirrelConversionImagePng,
  cartoon_guy_handshake_png,
  cartoon_guy_handshake,
  cartoon_guy_road_thumbs_up_png,
  cartoon_guy_road_thumbs_up,
  cartoon_guy_with_question_marks_png,
  cicdTourPageHeroImage,
  customCodeTourPageHeroImage,
  developerTourPageHeroImage,
  tourHomePageHeroImage,
  userTourPageHeroImage,
  contactUsPageHeroImage,
  faqPageHeroImage,
  homePageHeroImage,
  kitchenSinkPageHeroImage,
  ourApproachPageHeroImage,
  teamPageHeroImage,
  acornBlackImage,
  approachOverviewImage,
  architecturalOverviewImage,
  bwbHomepageImage,
  compass1Image,
  compass2Image,
  compass3Image,
  compass4Image,
  digitalizationImage,
  hamburgerMenuImage,
  mapImage,
  screenshotBwbHomepageImage,
  screenshotBwbGuestbookImage,
  screenshotDataAfterImage,
  screenshotDataBeforeImage,
  screenshotDfuAfterImage,
  screenshotDfuBeforeImage,
  screenshotDfuSourceAfterImage,
  screenshotDfuSourceBeforeImage,
  screenshotMenuAfterImage,
  screenshotMenuBeforeImage,
  screenshotMenuSourceAfterImage,
  screenshotMenuSourceBeforeImage,
  screenshotProcedureSourceAfterImage,
  screenshotProcedureSourceBeforeImage,
  screenshotProgramSourceAfterImage,
  screenshotProgramSourceBeforeImage,
  screenshotReportListAfterImage,
  screenshotReportListBeforeImage,
  screenshotReportAfterImage,
  screenshotReportBeforeImage,
  screenshotScreenAfterImage,
  featuresPageHeroImage,
  screenshotScreenBeforeImage,
  screenshotScreenSourceAfterImage,
  screenshotScreenSourceBeforeImage,
  user1Image,
  user2Image,
  user3Image,
  twinOakSquirrelIconImage,
  twinOakCombinedImage,
  wineImage,
  fourOhFourPageHeroImage,
  privacyPageHeroImage,
  policyPageHeroImage,
  termsOfUsePageHeroImage,
  tourUserImage,
  tourDeveloperImage,
};
