import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { BaseSyntheticEvent, CSSProperties, useEffect } from "react";
import { ExpandableImageProps } from "./ExpandableImageProps";
import { useExpandableImageState } from "./ExpandableImageProvider";

const captionedImageContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const overlayContainerStyle: CSSProperties = {
  display: "flex",
  position: "relative",
  margin: 0,
  padding: 0,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#C58138",
};

const bottomRightOverlayStyle: CSSProperties = {
  position: "absolute",
  bottom: 0,
  right: 0,
  transition: ".3s ease",
  background: "rgba(255, 255, 255,0.8)",
  display: "flex",
  padding: 2,
};

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const imgStyle: CSSProperties = {
  width: "100%",
  height: "100%",
};

/** A relatively small image that a user can click on to view a larger version of the image. */
export const ExpandableImage: React.FC<ExpandableImageProps & DivProps> = (
  props
) => {
  const {
    expandedCaption,
    expandedTitle,
    thumbnailCaption,
    src,
    ...divProps
  } = props;
  const expandableImageProps: ExpandableImageProps = {
    expandedTitle,
    expandedCaption,
    thumbnailCaption,
    src,
  };
  const {
    addImage,
    removeImage,
    setExpandedImageSrc,
  } = useExpandableImageState();
  function registerImage() {
    addImage(expandableImageProps);
  }
  function deregisterImage() {
    removeImage(expandableImageProps);
  }
  useEffect(() => {
    registerImage();
    return deregisterImage;
  }, []);

  function handleClick(event: BaseSyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();
    setExpandedImageSrc(props.src);
  }

  return (
    <div
      {...divProps}
      style={{ ...divProps.style, ...captionedImageContainerStyle }}
    >
      <a href="#" onClick={handleClick}>
        <div style={overlayContainerStyle}>
          <img src={src} style={imgStyle} />
          <div style={bottomRightOverlayStyle}>
            <FontAwesomeIcon icon={faExpandArrowsAlt} size="lg" />
          </div>
        </div>
      </a>
      {thumbnailCaption && <p>{thumbnailCaption}</p>}
    </div>
  );
};
